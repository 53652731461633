import request from "../utils/request";

const API = {
  GET_USER_URL:'/api/v2/User/getUser',         //获取用户信息
  PAGE_URL:'/api/v2/index/page',               //获取服务协议、隐私政策、关于我们
  PROFILE_URL:'/api/v2/User/profile',          //修改个人信息
  UP_AVATAR_URL:'/api/v2/User/upavatar',       //头像上传
  RESET_PWD_URL:'/api/v2/user/resetpwd',       //重置密码
  ADDRESS_ADD_URL:'/api/v2/integral/user_add', //添加地址
  GET_ADDRESS_URL:'/api/v2/integral/display',  //获取地址
  GET_MY_CARD_URL:'/api/v2/shop/times_card',   //我的次卡
  GET_SHOP_LIST_URL:'/api/v2/shop/get_shop_List',//店铺列表
  USER_CARD_URL:'/api/v2/shop/user_card'       //使用洗车卡
}

export const reqUser = () => request.get(API.GET_USER_URL)
export const reqPager = (data) => request.post(API.PAGE_URL,data)
export const reqProfile = (data) => request.post(API.PROFILE_URL,data)
export const reqUpAvatar = (data) => request.post(API.UP_AVATAR_URL,data)
export const reqResetPwd = (data) => request.post(API.RESET_PWD_URL,data)
export const reqAddressAdd = (data) => request.post(API.ADDRESS_ADD_URL,data)
export const reqGetAddress = () => request.post(API.GET_ADDRESS_URL)
export const reqGetMyCard = (data) => request.post(API.GET_MY_CARD_URL,data)
export const reqGetShopList = (data) => request.post(API.GET_SHOP_LIST_URL,data)
export const reqUserCard = (data) => request.post(API.USER_CARD_URL,data)