<template>
  <div class="address">
    <TopTabbar title="我的地址" text="返回"/>
    <div class="center">
      {{ address }}
    </div>
  </div>
</template>

<script setup>
  import { ref,onMounted,reactive } from 'vue';

  import { reqGetAddress } from '../../../API/user.js'

  const address = ref()

  onMounted(() => {
    getAddress()
  })

  //获取地址
  const getAddress = async () => {
    const result = await reqGetAddress()
    if(result.code == 1){
      address.value = result.data
      console.log(result);
    }
  }

</script>

<style lang="scss" scoped>
  .address{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #f7f8fa;
    .center{
      padding: 20px 15px;
      background-color: white;
      border-radius: 10px;
      margin: 10px;
    }
  }
  ::v-deep(.van-address-item__name){
    font-size: 20px;
  }
  ::v-deep(.van-address-item__address){
    font-size: 16px;
  }
</style>